import React from 'react';
import './index.css';

const Spinner = ({ isSpinner }) => {
    return (
        <>
            {isSpinner && (
                <div className="spinner"></div>
            )}
        </>
    );
};

export default Spinner;
