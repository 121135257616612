import React, { useEffect, useState } from 'react';
import { mongodbCrudOperations } from '../../../utils/MongoQueries/crudOperations/crudOperations';
import Spinner from '../../atom/spinner/spinner';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { BSON } from "realm-web";

const CompanyLisitng = () => {
    const [role,setRole] = useState('');
    const [isSpinner,setIsSpinner] = useState(false);
    const [companyList,setCompanyList] = useState([]);
    const navigate = useNavigate();
  const getCompanyData = async () => {

    setIsSpinner(true);
    try {
      // Replace with your logic to get the current user
      const currentUser = localStorage.getItem("userId");
      let obj1 = {
        type: 'findOne',
        collection: 'users',
        data: [{ _id: BSON.ObjectId(currentUser) }]
      };
      const usererData = await mongodbCrudOperations(obj1);
      if(usererData) {
        setRole(usererData.post)
      }
  
      if (role === 'SuperAdmin' || role === 'SiteAdmin') {
        let obj = {
          type: 'find',
          collection: 'company',
          data: [{}]
        };

        mongodbCrudOperations(obj).then((res) => {
          if (res && res.length) {
            if (role === 'SiteAdmin') {
              let filterArray = res.filter((e) => e?.userId && e.userId.includes(currentUser))
              
              if (filterArray.length) {
                setCompanyList(filterArray);
              } else {
                  setCompanyList([]);
              }
            } else if (role === 'SuperAdmin') {   
              setCompanyList(res);
            }
          } else {
            setCompanyList([]);
          }
          setIsSpinner(false);
        }).catch((error) => {
          console.error('Error:', error);
          setIsSpinner(false);
        });
      } else {
        setIsSpinner(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSpinner(false);
    }
  };

    

    useEffect(() => {
        getCompanyData();
    }, [role]);

    const handleDetail = (e) => {
        navigate(`/company-detail/${e._id}`);
    };
    return (
        <div>
            {
                isSpinner ? 
                <Spinner isSpinner={isSpinner} /> : 
                <div className='container lg:max-w-[100%] shadow-slate-900'>
                    <h2 className={'pb-[20px] text-[#ed1c24] leading-[1.2] font-family-Quicksand-SemiBold xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px]'}>Company Listing</h2>
                    <table className={`table-auto w-full lg:table block overflow-scroll`}>
                        <thead>
                            <tr className='bg-black'>
                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Sr no.</th>
                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Id</th>
                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Company Name</th>
                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Company CreatedAt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companyList && companyList.length ? companyList.map((e,index) =>
                                    <tr onClick={() => handleDetail(e)} className={`cursor-pointer ${companyList.length === index + 1 ? '' : 'border-b border-b-black'} ${index%2 ? 'bg-slate-100' : ''}`} key={index}>
                                        <td className="w-[100px] px-2 lg:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{index + 1}</td>
                                        <td className="w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e._id}</td>
                                        <td className="w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e.name}</td>
                                        <td className="w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{moment(e.createdAt).format('DD/MM/YYYY')}</td>
                                    </tr>
                                ):
                                <tr>
                                    <td colSpan="2" className="text-center py-4">No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default CompanyLisitng;