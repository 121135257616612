export function useCustomComposable() {
    function makeUniqueId(length = 6) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    }
    function debounce(func, timeout = 300) {
        let timer = null;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(null, args); }, timeout);
        };
    }
    return {
        makeUniqueId,
        debounce
    };
}

export default useCustomComposable;