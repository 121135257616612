import React,{ useEffect, useState, useCallback } from "react";
import Spinner from '../../atom/spinner/spinner.jsx';
import { mongodbCrudOperations } from "../../../utils/MongoQueries/crudOperations/crudOperations.js";
import { BSON } from "realm-web";
import { useParams,useNavigate } from 'react-router-dom';
import moment from "moment";
import Dropdown from "../../atom/dropDown.jsx";
import CheckBox from "../../molecules/checkBox/checkBox.jsx";
import { ToastContainer, toast } from 'react-toastify';
import QRCode from 'qrcode';
import useCustomComposable from '../../../utils/composables/index.js';
import Button from "../../atom/button.jsx";

const CompanyDetail = () => {
    const {makeUniqueId} = useCustomComposable();
    const { id } = useParams();
    const navigate = useNavigate();
    const [companyCardArray, setCompanyCardArray] = useState([]);
    const [companyDetail,setCompanyDetail] = useState({});
    const [isSpinner, setIsSpinner] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOption,setIsOption] = useState([]);
    const [qrCodeUrl,setQrCodeUrl] = useState([]);
    const [selectedOption,setSelectedOption] = useState([]);
    const getMasterCard = ((value) => {
        try {
            let obj = {
                type: 'find',
                collection: 'masterCards',
                data: [{}]
            }
            mongodbCrudOperations(obj).then((res) => {
                if(res && res.length){
                    if(value && value.length){
                        let notFilter = [];
                        let ids = value.map(ele => ele.cardId);
                        
                        res.forEach((element, index) => {
                            if (ids.includes(element._id)) {
                                notFilter.push(element._id);
                            }
                        
                            if (res.length === index + 1) {
                                setIsOption(res);
                                setSelectedOption(notFilter);
                                setIsSpinner(false);
                            }
                        });
                    }else{
                        setIsOption(res);
                        setSelectedOption([]);
                        setIsSpinner(false);
                    }
                }else{
                    setIsOption([]);
                    setIsSpinner(false);
                }
            });
        } catch (error) {
            console.error('Error', error);
            setIsSpinner(false);
        }
    });
    const getCompanyCard = useCallback(() => {
        try {
            let obj = {
                type: 'find',
                collection: 'companyCards',
                data: [{ siteAreaId: BSON.ObjectId(id) }]
            }
            
            mongodbCrudOperations(obj).then((res) => {
                if (res && res.length) {
                    setCompanyCardArray(res);
                }
                getMasterCard(res);
                
            });
        } catch (error) {
            console.error('Error', error);
            getMasterCard();
        }
    }, [id]);
    const getCompanyDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            let obj = {
                type: 'findOne',
                collection: 'siteAreas',
                data: [{ _id: BSON.ObjectId(id) }]
            }
            mongodbCrudOperations(obj).then(async(res) => {
                if (res) {
                    setCompanyDetail(res);
                }
                getCompanyCard();
                const dataUrl = await QRCode.toDataURL(res._id, { width: 256 });
                setQrCodeUrl(dataUrl);
                // getUsers()
            }).catch((error) => {
                console.error('compnay get Error', error);
                setIsSpinner(false);
                navigate('/404');
            });
        } catch (error) {
            console.error('compnay get Error', error);
            setIsSpinner(false);
            navigate('/404');
        }
    }, [id, getCompanyCard,navigate]);

    
    const handleDelete = (e) => {
        try {
            setIsLoading(true);
            const queryUpdate = {
                type: "deleteOne",
                collection: "companyCards",
                data: [
                    {
                        _id: BSON.ObjectID(e._id)
                    }
                ]
            };
            mongodbCrudOperations(queryUpdate).then(() => {
                setCompanyCardArray(prevCompanyDetail => prevCompanyDetail.filter(item => item._id !== e._id));
                let index = selectedOption.indexOf(e.cardId);
                if (index !== -1) {
                    selectedOption.splice(index, 1);
                }
                setSelectedOption(selectedOption);
                toast.success("deleted successfully", {
                    position: 'top-right',
                });
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error('deleting the company card', error);
            });
        } catch (error) {
            setIsLoading(false);
            console.error('deleting the company card',error);
        }
        
    };
    const handleUpdate = (e) => {
        try {
            setIsLoading(true);
            let object = {
                updatedAt:new Date(),
                isCompulsory:true,
                createdAt:new Date(),
                validation:'',
                companyId:BSON.ObjectID(id),
                cardId:BSON.ObjectID(e)
            }
            const queryUpdate = {
                type: "insertOne",
                collection: "companyCards",
                data: [object]
            };
            mongodbCrudOperations(queryUpdate).then((res) => {
                object._id = res.insertedId;
                object.companyId = JSON.parse(JSON.stringify(object.companyId)); 
                object.cardId = JSON.parse(JSON.stringify(object.cardId));
                setCompanyCardArray(prev => [...prev, object]);
                setSelectedOption(prev =>[...prev,object.cardId]);
                toast.success("added successfully", {
                    position: 'top-right',
                });
                setIsLoading(false);
            }).catch((error) => {
                console.error('inserting the company card',error);
                setIsLoading(false);
            });
        } catch (error) {
            console.error('inserting the company card',error);
            setIsLoading(false);
        }
    };
    const handleSelect = (value,e) => {
        try {
            setIsLoading(true);
            let queryObj = [
                { _id: BSON.ObjectID(e._id) },
                { $set: {isCompulsory: !value.value,updatedAt:new Date()}}
            ];
    
            const query = {
                type: "updateOne",
                collection: "companyCards",
                data: queryObj
            };
    
            mongodbCrudOperations(query)
            .then(() => {
                e.isCompulsory = !value.value;
                setCompanyCardArray(prev => {
                    const newArray = prev.map(item => {
                    if (item === e) {
                        return { ...item, isCompulsory: !value.value };
                    }
                    return item;
                    });
                    return newArray;
                });
                toast.success("updated successfully", {
                    position: 'top-right',
                });
                setIsLoading(false);
            })
            .catch((err)=>{
                console.error(err,"Error in Update ");
                setIsLoading(false);
            });
        } catch (error) {
            setIsLoading(false);
            console.error('update Error',error);
        }
    };

    const downloadQR = async () => {
        try {
            const dataUrl = await QRCode.toDataURL(companyDetail._id, { width: 256 });
            const link = document.createElement('a');
            link.download = `${makeUniqueId(5)}.png`;
            link.href = dataUrl;
            link.click();
        } catch (err) {
            console.error('Failed to generate QR code', err);
        }
    };
    useEffect(() => {
        getCompanyDetail();
    }, [getCompanyDetail]);

    return(
        <>
            
            <ToastContainer />
            {isLoading ? <Spinner isSpinner={isLoading} /> : ''}
            {
                isSpinner ? 
                <Spinner isSpinner={isSpinner} /> :
                <div className={`container lg:max-w-[100%] ${isLoading || isSpinner ? 'pointer-events-none' : ''}`}>
                    <div className="p-4 bg-white shadow-[0_0_10px_0px_rgba(0,0,0,0.2)] rounded-md">
                        <h2 className="pb-5 text-[#ed1c24] leading-tight font-semibold font-quicksand text-2xl xxms:text-xl xms:text-2xl ms:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                            Site Detail
                        </h2>
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-sm font-medium text-gray-600">Name</p>
                                    <span className="block text-lg font-semibold text-gray-800">{companyDetail.name}</span>
                                <p className="text-sm font-medium text-gray-600 mt-4">ID</p>
                                    <span className="block text-lg font-semibold text-gray-800">{companyDetail._id}</span>
                                <p className="text-sm font-medium text-gray-600 mt-4">Created At</p>
                                <span className="block text-lg font-semibold text-gray-800">
                                    {moment(companyDetail.createdAt).format('DD/MM/YYYY')}
                                </span>
                            </div>
                            <div>
                                <img src={qrCodeUrl} alt="eye" />
                                <Button
                                    class="bg-[#ed1c24] text-[#fff] mt-4 text-[18px] rounded-[50px] pt-[7.5px] px-[28px] leading-[normal] pb-[7.5px] font-family-Quicksand-SemiBold text-center block"
                                    buttonName="Download QR Code"
                                    onClick={() => downloadQR()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="pb-[20px] lg:flex lg:justify-between lg:items-center">
                            <h2 className={'lg:w-[30%] text-[#ed1c24] leading-[1.2] font-family-Quicksand-SemiBold xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px]'}>Company Cards</h2>
                            <div className="lg:w-[70%]">
                                <Dropdown
                                    options={isOption}
                                    onUpdate={(e) => handleUpdate(e)}
                                    multiSelect={true}
                                    selectedValue={selectedOption}
                                    selectClass={'border border-[#ced4da] flex-wrap rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5.3px] h-[auto]'}
                                    wrapperClass={'relative'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                    keys={'_id'}
                                    setDefault={false}
                                    placeholder={'Select Card'}
                                />
                            </div>
                        </div>
                        <table className={`table-auto w-full xl:table block overflow-scroll`}>
                            <thead>
                                <tr className='bg-black'>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] xl:min-w-[auto] min-w-[100px] leading-[normal]">Sr no.</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[250px] xl:min-w-[auto] min-w-[250px] leading-[normal]">Name</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] xl:min-w-[auto] min-w-[100px] leading-[normal]">Id</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] xl:min-w-[auto] min-w-[100px] leading-[normal]">Card ID</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] xl:min-w-[auto] min-w-[250px] leading-[normal]">Validation</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] xl:min-w-[auto] min-w-[100px] leading-[normal]">CreatedAt</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[150px] xl:min-w-[auto] min-w-[150px] leading-[normal]">Is Compulsory</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] xl:min-w-[auto] min-w-[100px] leading-[normal]"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companyCardArray && companyCardArray.length ? companyCardArray.map((e, index) =>
                                        <tr className={`${companyCardArray.length === index + 1 ? '' : 'border-b border-b-black'} ${index % 2 ? 'bg-slate-100' : ''}`} key={index}>
                                            <td className="w-[100px] px-2 xl:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{index + 1}</td>
                                            <td className="w-[250px] px-2 xl:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{isOption && isOption.length ? isOption?.find(ele => ele._id === e.cardId)?.name || '-' : '-'}</td>
                                            <td className="w-[100px] px-2 xl:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e._id}</td>
                                            <td className="w-[100px] px-2 xl:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e.cardId}</td>
                                            <td className="w-[155px] px-2 xl:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e.validation ? e.validation : '-'}</td>
                                            <td className="w-[150px] px-2 xl:min-w-[auto] min-w-[150px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{moment(e.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="w-[100px] px-2 xl:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">
                                            <CheckBox
                                                checkBoxClassWrapper={'relative'}
                                                modelValue={e.isCompulsory}
                                                isDisabled={false}
                                                isReadonly={false}
                                                label={''}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onClick={(value) => handleSelect(value,e)}
                                                inputClass={'setting-styled-checkbox opacity-0 absolute'}
                                                labelClass={'cursor-pointer relative'}
                                                checked={e.isCompulsory}
                                                isCheckBy={true}
                                            />
                                            </td>
                                            <td onClick={() => handleDelete(e)} className="w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke cursor-pointer text-red-500">Delete</td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan="7" className="text-center py-4">No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    );
};

export default CompanyDetail;