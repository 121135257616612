import React, { useEffect, useState,useCallback } from 'react';
import { mongodbCrudOperations } from '../../../utils/MongoQueries/crudOperations/crudOperations';
import Spinner from '../../atom/spinner/spinner';
import moment from 'moment';
import { useNavigate,useParams } from 'react-router-dom';
import { BSON } from "realm-web";

const SiteLisitng = () => {
    const [companyDetail,setCompanyDetail] = useState({});
    const [userScandetail,setUserScanDeatil] = useState({});
    const [isSpinner,setIsSpinner] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Adjust pageSize as per your requirement


    const getCompanyDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            let obj = {
                type: 'findOne',
                collection: 'users',
                data: [{ _id: BSON.ObjectId(id) }]
            }
            mongodbCrudOperations(obj).then((res) => {
                if (res) {
                    setCompanyDetail(res);
                }
                setIsSpinner(false);
            }).catch((error) => {
                console.error('company get Error', error);
                setIsSpinner(false);
                navigate('/404');
            });
        } catch (error) {
            console.error('company get Error', error);
            setIsSpinner(false);
            navigate('/404');
        }
    }, [id]);

    const getVisitorsLoggedDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            const aggregationPipeline = [
                {
                    $match: 
                    { 
                        userId: BSON.ObjectId(id),
                    },
                },
                {
                    $lookup: {
                        from: 'siteAreas', // The collection to join
                        localField: 'siteAreaId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'siteAreas',
                        pipeline: [
                            {
                                $project: {
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $sort:{createdAt:-1}
                },
            ];
    
            const obj = {
                type: 'aggregate',
                collection: 'usersScanHistory',
                data: [aggregationPipeline]
            };
    
            mongodbCrudOperations(obj)
                .then((res) => {
                    if (res) {
                        setUserScanDeatil(res);
                    }
                    setIsSpinner(false);
                })
                .catch((error) => {
                    console.error('Logs get Error', error);
                    setIsSpinner(false);
                });
        } catch (error) {
            console.error('Logs get Error', error);
            setIsSpinner(false);
        }
    }, [id]);



    useEffect(() => {
        getCompanyDetail()
        getVisitorsLoggedDetail()
    }, [id]);
    const totalPages = Math.ceil(userScandetail.length / pageSize);

    const goToPage = (page) => {
        setCurrentPage(page);
    };
    return (
        <div>
            {
                isSpinner ? 
                <Spinner isSpinner={isSpinner} /> : 

                <div className='container lg:max-w-[100%] shadow-slate-900'>

                    <div className="p-4 bg-white shadow-[0_0_10px_0px_rgba(0,0,0,0.2)] rounded-md">
                        <h2 className="pb-5 text-[#ed1c24] leading-tight font-semibold font-quicksand text-2xl xxms:text-xl xms:text-2xl ms:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                            User Detail
                        </h2>
                        <div>
                            <p className="text-sm font-medium text-gray-600">Name</p>
                                <span className="block text-lg font-semibold text-gray-800">{companyDetail.name}</span>
                            <p className="text-sm font-medium text-gray-600 mt-4">ID</p>
                                <span className="block text-lg font-semibold text-gray-800">{companyDetail._id}</span>
                            <p className="text-sm font-medium text-gray-600 mt-4">Created At</p>
                            <span className="block text-lg font-semibold text-gray-800">
                                {moment(companyDetail.createdAt).format('DD/MM/YYYY')}
                            </span>

                        </div>
                    </div>

                    <div className="flex justify-start space-x-2 mt-6 mb-2">
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white bg-red-600`}
                        >
                            Site Listing
                        </button>
                    </div>
                    <div>
                        <table className={`table-auto w-full lg:table block overflow-scroll`}>
                            <thead>
                                <tr className='bg-black'>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userScandetail && userScandetail.length && userScandetail
                                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                    .map((e, index) => (
                                        <tr
                                            className={`cursor-pointer ${
                                                index % 2 ? 'bg-slate-100' : ''
                                            } border-b border-b-black`}
                                            key={index}
                                        >
                                            <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.siteAreas[0]?.name}
                                            </td>
                                            <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                            </td>
                                            <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                {moment(e.createdAt).format('DD/MM/YYYY')}
                                            </td>
                                            <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {moment(e.createdAt).format('HH:mm:ss')}
                                            </td>
                                        </tr>
                                    ))}
                                    {userScandetail.length === 0 && (
                                    <tr>
                                        <td colSpan='6' className='text-center py-4'>
                                            No Record Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>   
                        <div className='flex justify-between items-center mt-4'>
                            <div className={'space-x-2'}>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                            <div className='text-xl'>Page {currentPage} of {totalPages}</div>
                        </div>
                    </div>

                </div>

            }
        </div>
    );
};

export default SiteLisitng;