import React, { createContext, useContext, useEffect, useState } from 'react';
import { App, Credentials } from "realm-web";
import { Navigate } from 'react-router-dom';
import { BSON } from "realm-web";
import { mongodbCrudOperations } from "../../../utils/MongoQueries/crudOperations/crudOperations";
// import { mongoConnection } from '../../../utils/MongoQueries/mongoAuth';


const RealmAppContext = createContext();

const app = new App({id: 'application-0-oszejan'});

export const RealmAppProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        checkLogged();
    }, [])

    const loginEmailPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            const credentials = Credentials.emailPassword(email, password);
            app.logIn(credentials)
            .then(async(user) => {
                // await mongoConnection();
                // setUser(user);
                getUsers();
                resolve(user);
            })
            .catch(error => {
                reject(error);
            });
        },);
    };

    function checkLogged() {
        return new Promise(async (resolve, reject) => {
            try {
                if(app.currentUser) {
                    // await mongoConnection();
                    resolve(app.currentUser);
                    getUsers()
                    // setUser(app.currentUser);
                } else {
                    resolve(null);
                    setUser(null);
                }
            } catch (error) {
                reject(error)
            }
        })
    }


    const getUsers = () => {
        try{
            let id = app.currentUser.id
            localStorage.setItem("userId", id);
            let obj = {
                type:'findOne',
                collection:'users',
                data: [{ _id: BSON.ObjectId(id) }]
            }
            mongodbCrudOperations(obj).then((data) => {
                setUser(data)
                localStorage.setItem("post", data.post);
            })
            .catch((error) => {
                console.error("ERR: ", error);
            })
        }catch(error){
            console.error('Error', error);
        }
    }


    const logout = async () => {
        await app.currentUser?.logOut();
        setUser(null);
        window.location.reload();
        localStorage.clear();
        <Navigate to="/login" />
    };

    return (
        <RealmAppContext.Provider value={{ user, loginEmailPassword, logout, checkLogged }}>
            {children}
        </RealmAppContext.Provider>
       
    );
};

export const useRealmApp = () => {
    return useContext(RealmAppContext);
};




