import { DB_REF, mongoConnection } from "../mongoAuth.js";

/**
 * 
 * @param {Object} data
 *  - type: "operation type" eg: find, findOne, update, etc
 *  - collection: "collection_name"
 *  - data: Array of data to be passed such as [{age: {$gt: 25}, {status: "mature"}, {options}}]
 * @returns 
 */
export const mongodbCrudOperations = (data) => {
    return new Promise(async(resolve, reject) => {
        try {
            if(data.data === undefined || data.type === undefined || data.collection === undefined)  {
				reject(new Error("Data, Type and collection is required"));
				return;
            }
            await mongoConnection();
            let collection = null;
            collection = DB_REF.value.collection(data.collection);
            collection[data.type].apply(collection, data.data).then((response) => {
                if(response){
                    let convertRes = JSON.parse(JSON.stringify(response));
                    resolve(convertRes);
                }else{
                    resolve(response);
                }
            }).catch((error)=>{
                reject(error);
                console.error(error);
            });
        } catch (error) {
            reject(error);
        }
    })
};