import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SignIn from '../src/components/templates/authentication/signIn/signIn.jsx';
import CompanyLisitng from './components/templates/companyLisitng/companyLisitng.jsx';
import { RealmAppProvider, useRealmApp } from '../src/components/router/realmProvider/realmProvider.js';
// import { App } from "realm-web";
import NotFound from './components/templates/notFound/notFound.jsx';
import { ClientWidthProvider } from '../src/components/atom/clientWidth.jsx';
import CompanyDetail from './components/templates/companyDetail/companyDetails.jsx'
import UserDeatil from './components/templates/userDetail/userDetail.jsx'
import SiteListing from './components/templates/companyLisitng/siteDetail.jsx';
import Header from './components/templates/header/header.jsx';
import BreadCrumbs from './components/templates/breadcrumbs/breadCrumbs.jsx';
import { mongodbCrudOperations } from "../src//utils/MongoQueries/crudOperations/crudOperations";

function Apps() {
    // const app = new App({ id: 'application-0-oszejan' });
    const [userDetail,setUserDetail] = useState(null);
    const PrivateRoute = ({ element }) => {
        const { user } = useRealmApp();
        useEffect(() => {
            if (user) {
                setUserDetail(user);
            }
        }, [user]);
        const isLogging = userDetail;
        return isLogging ? element : <Navigate to="/login" />;
    };
    
    const LoginRoute = () => {
        const { user } = useRealmApp();

        useEffect(() => {
            if (user) {
                setUserDetail(user);
            }
        }, [user]);
        
        const isUser = userDetail?.post !== "User";
        
        return userDetail && isUser ? <Navigate to="/" /> : <SignIn />;
    };



    const MainContent = () => {
        const location = useLocation();
        const { logout } = useRealmApp();

        return (
            <>
                {
                    location.pathname !== '/login' && userDetail &&
                    <>
                        
                        <Header 
                            userDetail={userDetail}
                            signOut={() => logout}
                        />
                        <BreadCrumbs />
                    </>
                }
                <Routes>
                    <Route path="/login" element={<LoginRoute />} />
                    <Route path="/" element={<PrivateRoute element={<CompanyLisitng />} />} />
                    <Route path="/site-detail/:id" element={<PrivateRoute element={<SiteListing />} />} />
                    <Route path="/company-detail/:id" element={<PrivateRoute element={<CompanyDetail />} />} />
                    <Route path="/user-detail/:id" element={<PrivateRoute element={<UserDeatil />} />} />
                    <Route path="*" element={<PrivateRoute element={<NotFound />} />} />
                </Routes>
            </>
        );
    };

    return (
        <div className="App">
            <ClientWidthProvider>
                <RealmAppProvider>
                    <BrowserRouter>
                        <MainContent />
                    </BrowserRouter>
                </RealmAppProvider>
            </ClientWidthProvider>
        </div>
    );
}

export default Apps;
