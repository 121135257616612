import { useLocation, Link } from 'react-router-dom';

const routeNameMap = {
  'company-detail': 'Company Details',
  'site-detail': 'Site Detail',
};

const BreadCrumbs = () => {
  const location = useLocation();
  let pathnames = location.pathname.split('/').filter((x) => x);
if (pathnames[0] === "site-detail") {
    pathnames = ["company-detail", ...location.pathname.split('/').filter((x, index) => index > 0)];
  }
  return (
    <div className="px-[15px]">
      <nav>
        <ul className="flex space-x-2">
          <li>
            <Link to="/" className={pathnames.length === 0 ? 'font-bold' : ''}>Home</Link>
          </li>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            const displayName = routeNameMap[value] || value;

            return (
              <li key={to} className="flex items-center">
                <span className="mx-2">/</span>
                {isLast ? (
                  <span className="font-bold">{displayName}</span>
                ) : (
                  <Link to={to}>{displayName}</Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default BreadCrumbs;
