import { useEffect, useState } from "react";
import Button from "../../../atom/button.jsx";
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
import Eye from "../../../../assets/images/png/eye.png";
import Hidden from "../../../../assets/images/png/hidden.png";
import { useRealmApp } from '../../../router/realmProvider/realmProvider.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mongodbCrudOperations } from "../../../../utils/MongoQueries/crudOperations/crudOperations";
import Spinner from '../../../atom/spinner/spinner.jsx';

const SignIn = () => {
    const { logout } = useRealmApp();
    const { loginEmailPassword} = useRealmApp();
    const [isSpinner,setIsSpinner] = useState(false);

    const { checkErrors, checkAllFields } = useValidation();
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [formData, setFormData] = useState({
        email: { value: "", rules: "required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+", name: "Email", error: "" },
        password: { value: "", rules: "required", name: "Password", error: "" }
    });

    useEffect(() => {
        setStayLoggedIn(stayLoggedIn);
    }, [stayLoggedIn]);


    
    
    const handleSignIn = () => {
        checkAllFields(formData).then(async (valid) => {
            setFormData({ ...formData });
            if (valid) {
                await loginEmailPassword(formData.email.value, formData.password.value).then(async(user) => {
                    let obj1 = {
                        type: 'findOne',
                        collection: 'users',
                        data: [{ email: formData.email.value }]
                    };
                    const usererData =  await mongodbCrudOperations(obj1);
                    if (usererData.post == "SiteAdmin" ||  usererData.post == "SuperAdmin") {
                        toast.success("login successfully", {
                            position: 'top-right',
                        });
                    }else{
                        logout();
                        setTimeout(() => {
                            toast.error("Not authorized.", {
                                position: 'top-right',
                            });
                        });
                        // setIsSpinner(true)
                    }
                }).catch(error => {
                    if (error.error === 'invalid username/password') {
                        toast.error("Invalid username or password.", {
                            position: 'top-right',
                        });
                    } else {
                        toast.error(error.error, {
                            position: 'top-right',
                        });
                    }
                });
            }
        });
    };

    
  


    const handleToggle = () => {
        setTogglePassword(!togglePassword);
        if (!togglePassword) {
            document.getElementById('password').type = 'text';
        } else {
            document.getElementById('password').type = 'password';
        }
    };

    



    return (

        <div>

     {
         isSpinner ? 
         <Spinner isSpinner={isSpinner} /> : 
        <div className="min-h-screen flex items-center justify-center">
            <ToastContainer />
            <div className="relative max-w-[600px] w-full m-auto px-[15px]">
                <h2 className="pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px]">Welcome! Please Login</h2>
                <form onSubmit={(event) => { event.preventDefault(); handleSignIn(); }} className="mt-[25px] mb-[20px]">
                    <div className="lg:mb-[22px] mb-[12px] relative">
                        <CustomInput
                            inputClass="border border-black w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]"
                            class="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]"
                            label="Email ID *"
                            type="text"
                            modelValue=""
                            placeHolder="Enter email id"
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onChange={(value, event) => {
                                formData.email.value = value;
                                checkErrors({
                                    'field': formData.email,
                                    'name': 'email',
                                    'validations': formData.email.rules,
                                    'type': formData.email.type,
                                    'event': event,
                                    'value': value
                                });
                                setFormData({ ...formData });
                            }}
                        />
                        {formData.email.error &&
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.email.error}</small>
                        }
                    </div>
                    <div className="mb-[12px] relative">
                        <CustomInput
                            inputId="password"
                            inputClass="border border-black w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]"
                            class="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]"
                            label="Password *"
                            type="password"
                            modelValue=""
                            placeHolder="Enter password"
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onChange={(value, event) => {
                                formData.password.value = value;
                                checkErrors({
                                    'field': formData.password,
                                    'name': 'password',
                                    'validations': formData.password.rules,
                                    'type': formData.password.type,
                                    'event': event,
                                    'value': value
                                });
                                setFormData({ ...formData });
                            }}
                        />
                        <div className="w-[20px] h-[20px] absolute cursor-pointer top-[44px] right-[15px]" onClick={(event) => { event.preventDefault(); handleToggle(); }}>
                            {
                                !togglePassword ?
                                    <img src={Eye} alt="eye" /> :
                                    <img src={Hidden} alt="Hidden" />
                            }
                        </div>
                        {formData.password.error &&
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.password.error}</small>
                        }
                    </div>
                    <div className="py-[15px]">
                        <Button
                            class="bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[7.5px] px-[28px] leading-[normal] pb-[7.5px] font-family-Quicksand-SemiBold text-center block mx-auto"
                            buttonName="Login"
                        />
                    </div>
                    
                </form>
            </div>
        </div>
        }
        </div>

    );
}

export default SignIn;
