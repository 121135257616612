import { useState, useEffect } from 'react';
import useCustomComposable from '../../utils/composables/index.js';
const InputField = (props) => {
    const {makeUniqueId} = useCustomComposable();
    const [value, setValue] = useState(props.modelValue);
    const [id] = useState(props.inputId ? props.inputId : makeUniqueId(6))

    const isValid = () => {
        if (!props.isRequired) {
            return true;
        } else {
            return (
                value.length !== 0 &&
                value.length >= props.minLength &&
                value.length <= props.maxLength
            );
        }
    };

    useEffect(() => {
        setValue(props.modelValue);
    }, [props.modelValue]);

    useEffect(() => {
        if (props.isDirectFocus) {
            const ele = document.getElementById(id);
            ele.focus();
        }
    }, [props.isDirectFocus, id]);

    const handleInputChange = (event) => {
        const newValue = props.isCheckBy ? event.target.checked : event.target.value;
        setValue(newValue);
        if(props.onChange){
            props.onChange(newValue,event);
        }
    };

    return (
        <input
            className={props.inputClass}
            type={props.type}
            checked={props.checked ? props.checked : false}
            value={value}
            id={id}
            name={props.name ? props.name : ''}
            maxLength={props.maxLength ? props.maxLength : ''}
            placeholder={props.placeHolder ? props.placeHolder : ''}
            disabled={props.isDisabled ? props.isDisabled : ''}
            readOnly={props.isReadonly ? props.isReadonly : ''}
            onChange={handleInputChange}
            onFocus={(event) =>
                props.onFocus ? props.onFocus({
                    value: value,
                    isValid: isValid(),
                    event: event
                }): ''
            }
            onBlur={(event) =>
                props.onBlur ? props.onBlur({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onClick={(event) =>
                props.onClick ? props.onClick({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onKeyPress={(event) =>
                props.onKeyPress ? props.onKeyPress({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onKeyUp={(event) =>
                props.onKeyUp ? props.onKeyUp({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onKeyDown={(event) =>
                props.onKeyDown ? props.onKeyDown({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onPaste={(event) =>
                props.onPaste ? props.onPaste({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onMouseUp={(event) =>
                props.onMouseUp ? props.onMouseUp({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
            onMouseDown={(event) =>
                props.onMouseDown ? props.onMouseDown({
                    value: value,
                    isValid: isValid(),
                    event: event
                }) : ''
            }
        />
    );
};

export default InputField;