import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import defaultImage from "../../../assets/images/png/default_user.png";
import { useRealmApp } from '../../router/realmProvider/realmProvider.js';

const Header = ({userDetail,signOut}) => {
    const { logout,user } = useRealmApp();
    const [isOpen,setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const imageRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && imageRef.current && !imageRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const handleSignOut = async() => {
        setIsOpen(true);
        await logout();
        signOut();
    };
    return(
        <div className="py-[15px] px-[15px]">
            <div className="flex flex-row items-center justify-end relative">
                <span className="mr-[15px]">{user ? user?.customData?.email : userDetail?.customData?.email}</span>
                <img ref={imageRef} src={defaultImage} alt="defaultImage" className='cursor-pointer' onClick={() => setIsOpen(true)} />
                {
                    isOpen ?
                        <div ref={dropdownRef} className='shadow-[0_0_10px_0px_rgba(0,0,0,0.2)] rounded-md block ease-in-out absolute md:right-0 w-[240px] top-[calc(100%+10px)] bg-white rounded-[12px] p-[10px]'>
                            <ul>
                                <li  onClick={() => handleSignOut()} className='mb-[6px] leading-[normal]'>
                                    <Link className='leading-[normal] text-[#262626] text-[16px] font-family-Quicksand-Medium'>Sign Out</Link>
                                </li>
                            </ul>
                        </div>
                    : null
                }
            </div>
        </div>
    );
};

export default Header