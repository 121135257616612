import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import useCustomComposable from '../../utils/composables/index.js';
import ClientWidthContext from '../atom/clientWidth.jsx';
const CustomDropdown = ({ isOpen, position,keys, options, onClose, onOptionClick, multiSelect,uniqueId,optionsClass,optionsWrapper,selectDropDown }) => {
    const clientWidth = useContext(ClientWidthContext);
    const dropdownRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState({});
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchValue,setSearchValue] = useState('');
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target) ) {
                onClose(dropdownRef.current);
            }
        };

        if (isOpen) {
            if(clientWidth >= 768){
                if (dropdownRef.current) {
                    const { height } = document.getElementById(uniqueId).getBoundingClientRect();
                    const { top, left, bottom,width } = document.getElementById(uniqueId).getBoundingClientRect();
                    let newTop;
                    if (bottom + height > window.innerHeight) {
                        newTop = top - height;
                    } else {
                        newTop = bottom;
                    }
                    setDropdownPosition({ position:'absolute' ,top: newTop + window.scrollY, left:left ,width:width });
                }
            }else{
                setDropdownPosition({ position:'fixed',bottom: '0', left:'0',width:'100%' });
            }
            setFilteredOptions(options);
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen,selectDropDown,position, options, onClose,uniqueId,clientWidth]);

    const handleOptionClick = (option) => {
        if (multiSelect) {
            onOptionClick(option[keys],searchValue);
        } else {
            onOptionClick([option[keys]]); // Pass the name of the clicked option to the parent component
            onClose();
        }
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value)
        const filtered = options.filter(option =>
            option.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    return isOpen ? (
        ReactDOM.createPortal(
            <div>
                {clientWidth < 768 ? <div className='z-10 h-[100dvh] w-full top-0 left-0 opacity-100 fixed bg-[#000000b3]'></div> : ''}
                <div ref={dropdownRef} className={'z-20 bg-white rounded-t-[12px] md:rounded-[12px] pt-[20px] pb-[15px]'} style={{ position: dropdownPosition.position, bottom: dropdownPosition.bottom, top: dropdownPosition.top, left: dropdownPosition.left, width:dropdownPosition.width, 'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.15)' }}>
                    <div className='mx-[20px]'>
                        <input type='text' className='w-full border-[1px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                    </div>
                    <ul className={`${optionsWrapper} max-h-[240px] overflow-x-auto`}>
                        {filteredOptions.length ? filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                onClick={() => handleOptionClick(option,index)}
                                className={`${optionsClass} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px]`}
                            >
                                {option.name}
                            </li>
                        )): <li className='px-[20px] py-[5px]'>no data found</li>}
                    </ul>
                </div>
            </div>,
            document.body
        )
    ) : null;
};

const Dropdown = ({ options,keys,placeholder, multiSelect,onUpdate,selectedValue,id,labelName,labelClass,selectClass,wrapperClass,optionsClass,optionsWrapper }) => {
    const {makeUniqueId} = useCustomComposable();
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({});
    const [selectedOption, setSelectedOption] = useState(selectedValue ? selectedValue : []);
    const [selectedOptions, setSelectedOptions] = useState(selectedValue ? selectedValue : []); // State to store selected options in case of multi-select
    const [uniqueId] = useState(id ? id : makeUniqueId(6));
    const selectDropDown = useRef(null);
    useEffect(() => {
        if(selectedValue?.length > -1){
            if(multiSelect){
                setSelectedOptions(selectedValue);
            }else{
                setSelectedOption(selectedValue);
            }
        }
    },[selectedValue,multiSelect])
    const handleClick = (e) => {
        setIsOpen(!isOpen);
        setPosition(e.target.getBoundingClientRect());
    };

    const handleClose = (e) => {
        setIsOpen(false);
    };

    const handleOptionClick = (element,value) => {
        if (multiSelect) {
            setSelectedOptions([...selectedOptions,element]);
            onUpdate(element);
        } else {
            setSelectedOption(element);
            const selectOption = element;
            let optionArray = [];
            if(selectOption && selectOption.length){
                optionArray = options.find(e => selectOption.includes(e[keys]));
            }
            onUpdate(optionArray);
        }
    };
    return (
        <div className={wrapperClass ? wrapperClass : ''}>
            {labelName ? <label className={labelClass ? labelClass : ''}>{labelName}</label> : ''}
            <div ref={selectDropDown} className={`${selectClass ? selectClass : ''} cursor-pointer`} id={uniqueId} onClick={handleClick}>
                {multiSelect ? 
                    (selectedOptions.length ? (
                        options.filter(e => selectedOptions.includes(e[keys])).map((ele,index) =>
                            <div onClick={(event) => {event.preventDefault()}} key={index} className='border border-[#00000080] text-[#262626] text-[16px] rounded-[5px] px-[10px] py-[4px] mr-[3.75px] my-[3.75px]'>
                                <span className='text-[#262626] text-[16px] font-family-Quicksand-SemiBold'>{ele.name}</span>
                            </div>
                        )
                    ) : placeholder ? placeholder : "Select") : 
                    (selectedOption && selectedOption.length ? <span className='text-[#262626] text-[18px] font-family-Quicksand-Medium text-stroke-2'>{options.find(e => selectedOption.includes(e[keys]))?.name}</span>  :  placeholder ? placeholder : "Select")}
            </div>
            <CustomDropdown 
                isOpen={isOpen}
                position={position}
                options={multiSelect ? selectedOptions && selectedOptions.length ? options.filter(e => !selectedOptions.includes(e[keys])) : options : options}
                onClose={handleClose}
                onOptionClick={handleOptionClick}
                multiSelect={multiSelect}
                uniqueId={uniqueId}
                optionsClass={optionsClass ? optionsClass : ''}
                optionsWrapper={optionsWrapper ? optionsWrapper : ''}
                keys={keys}
                selectDropDown={selectDropDown}
            />
        </div>
    );
};

export default Dropdown;