import React, { useEffect, useState,useCallback } from 'react';
import { mongodbCrudOperations } from '../../../utils/MongoQueries/crudOperations/crudOperations';
import Spinner from '../../atom/spinner/spinner';
import moment from 'moment';
import { useNavigate,useParams } from 'react-router-dom';
import { BSON } from "realm-web";

const SiteLisitng = () => {
    const [companyDetail,setCompanyDetail] = useState({});
    const [workerDetail,setWorkerDetail] = useState({});
    const [visitorscanDetail,setvisitorscanDetail] = useState({});
    const [allActivityLogsDetail,setallActivityLogsDetail] = useState({});
    const [isSpinner,setIsSpinner] = useState(false);
    const [siteList,setSiteList] = useState([]);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('allListing');
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [userPost] = useState(localStorage.getItem("post"));
    const [pageSize] = useState(10); // Adjust pageSize as per your requirement

    const getCompanyData = async() => {
        setIsSpinner(true);
        try {
            let obj = {
                type: 'find',
                collection: 'siteAreas',
                data: [{ companyId: BSON.ObjectId(id) }]
            }
            mongodbCrudOperations(obj).then((res) => {
                if(res && res.length){
                    setSiteList(res);
                }
                setIsSpinner(false);
            }).catch((error)=>{
                console.error('error',error);
                setIsSpinner(false);
            })
        } catch (error) {
            console.error('error',error);
            setIsSpinner(false);
        }
    };

    

    const getCompanyDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            let obj = {
                type: 'findOne',
                collection: 'company',
                data: [{ _id: BSON.ObjectId(id) }]
            }
            mongodbCrudOperations(obj).then((res) => {
                if (res) {
                    setCompanyDetail(res);
                }
             
                // getUsers()
            }).catch((error) => {
                console.error('company get Error', error);
                setIsSpinner(false);
                navigate('/404');
            });
        } catch (error) {
            console.error('company get Error', error);
            setIsSpinner(false);
            navigate('/404');
        }
    }, [id]);

    const getWorkerDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            const aggregationPipeline = [
                {
                    $match: { 
                        companyId: BSON.ObjectId(id),
                        post: "Worker"
                    }
                },
                {
                    $lookup: {
                        from: 'users', // The collection to join
                        localField: 'userId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'userData',
                        pipeline: [
                            {
                                $project: {
                                    email: 1, // Include only the email field
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $lookup: {
                        from: 'siteAreas', // The collection to join
                        localField: 'siteAreaId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'siteAreas',
                        pipeline: [
                            {
                                $project: {
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $sort:{createdAt:-1}
                },
            ];
    
            const obj = {
                type: 'aggregate',
                collection: 'usersScanHistory',
                data: [aggregationPipeline]
            };
    
            mongodbCrudOperations(obj)
                .then((res) => {

                    if (res) {
                        setWorkerDetail(res);
                    }
                    setIsSpinner(false);
                })
                .catch((error) => {
                    console.error('Logs get Error', error);
                    setIsSpinner(false);
                });
        } catch (error) {
            console.error('Logs get Error', error);
            setIsSpinner(false);
        }
    }, [id]);

    const getVisitorsLoggedDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            const aggregationPipeline = [
                {
                    $match: 
                    { 
                        companyId: BSON.ObjectId(id),
                        post: "Visitor"
                    },
                },
                {
                    $lookup: {
                        from: 'users', // The collection to join
                        localField: 'userId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'userData',
                        pipeline: [
                            {
                                $project: {
                                    email: 1, // Include only the email field
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $lookup: {
                        from: 'siteAreas', // The collection to join
                        localField: 'siteAreaId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'siteAreas',
                        pipeline: [
                            {
                                $project: {
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $sort:{createdAt:-1}
                },
            ];
    
            const obj = {
                type: 'aggregate',
                collection: 'usersScanHistory',
                data: [aggregationPipeline]
            };
    
            mongodbCrudOperations(obj)
                .then((res) => {
                    if (res) {
                        setvisitorscanDetail(res);
                    }
                    setIsSpinner(false);
                })
                .catch((error) => {
                    console.error('Logs get Error', error);
                    setIsSpinner(false);
                });
        } catch (error) {
            console.error('Logs get Error', error);
            setIsSpinner(false);
        }
    }, [id]);

    const getallActivityLoggedDetail = useCallback(() => {
        setIsSpinner(true);
        try {
            const aggregationPipeline = [
                {
                    $match: 
                    { 
                        companyId: BSON.ObjectId(id)
                    },
                },
                {
                    $lookup: {
                        from: 'users', // The collection to join
                        localField: 'userId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'userData',
                        pipeline: [
                            {
                                $project: {
                                    email: 1, // Include only the email field
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $lookup: {
                        from: 'siteAreas', // The collection to join
                        localField: 'siteAreaId', // The field from usersScanHistory
                        foreignField: '_id', // The field from users
                        as: 'siteAreas',
                        pipeline: [
                            {
                                $project: {
                                    name: 1, // Include only the email field
                                }
                            }
                        ]
                    }
                },
                {
                    $sort:{createdAt:-1}
                },
            ];
    
            const obj = {
                type: 'aggregate',
                collection: 'usersScanHistory',
                data: [aggregationPipeline]
            };
    
            mongodbCrudOperations(obj)
                .then((res) => {
                    if (res) {
                        setallActivityLogsDetail(res);
                    }
                    setIsSpinner(false);
                })
                .catch((error) => {
                    console.error('Logs get Error', error);
                    setIsSpinner(false);
                });
        } catch (error) {
            console.error('Logs get Error', error);
            setIsSpinner(false);
        }
    }, [id]);



    useEffect(() => {
        getCompanyData();
        getCompanyDetail()
        getWorkerDetail()
        getVisitorsLoggedDetail();
        getallActivityLoggedDetail();
    }, []);

    const handleDetail = (e) => {
        navigate(`/site-detail/${e._id}`);
    };

    const handleUserDeatil = (e) => {
        navigate(`/user-detail/${e.userId}`);
    };

    const setTabs = async (tabName) => {
        setActiveTab(tabName);
        setCurrentPage(1);
    }
    
      const totalPages = Math.ceil(activeTab === 'workerLogs' ? workerDetail.length / pageSize : activeTab === 'allActivityLogs' ? allActivityLogsDetail.length / pageSize : visitorscanDetail.length / pageSize);

      const goToPage = (page) => {
          setCurrentPage(page);
      };
    return (
        <div>
            {
                isSpinner ? 
                <Spinner isSpinner={isSpinner} /> : 

                <div className='container lg:max-w-[100%] shadow-slate-900'>

                    <div className="p-4 bg-white shadow-[0_0_10px_0px_rgba(0,0,0,0.2)] rounded-md">
                        <h2 className="pb-5 text-[#ed1c24] leading-tight font-semibold font-quicksand text-2xl xxms:text-xl xms:text-2xl ms:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                            Company Detail
                        </h2>
                        <div>
                            <p className="text-sm font-medium text-gray-600">Name</p>
                                <span className="block text-lg font-semibold text-gray-800">{companyDetail.name}</span>
                            <p className="text-sm font-medium text-gray-600 mt-4">ID</p>
                                <span className="block text-lg font-semibold text-gray-800">{companyDetail._id}</span>
                            <p className="text-sm font-medium text-gray-600 mt-4">Created At</p>
                            <span className="block text-lg font-semibold text-gray-800">
                                {moment(companyDetail.createdAt).format('DD/MM/YYYY')}
                            </span>

                        </div>
                    </div>


                    <div className="flex justify-start space-x-2 mt-6 mb-2">
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white ${activeTab === 'allListing' ? 'bg-red-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                            onClick={() => setTabs('allListing')}
                        >
                            All
                        </button>
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white ${activeTab === 'siteListing' ? 'bg-red-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                            onClick={() => setTabs('siteListing')}
                        >
                            Site Listing
                        </button>
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white ${activeTab === 'allActivityLogs' ? 'bg-red-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                            onClick={() => setTabs('allActivityLogs')}
                        >
                            Activity Log
                        </button>
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white ${activeTab === 'visitersLogs' ? 'bg-red-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                            onClick={() => setTabs('visitersLogs')}
                        >
                            Visitors Log
                        </button>
                        <button
                            className={`py-2 px-4 rounded-lg font-semibold text-white ${activeTab === 'workerLogs' ? 'bg-red-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                            onClick={() => setTabs('workerLogs')}
                        >
                            Worker Log
                        </button>
                    </div>

                    {activeTab === 'allListing' && (
                        <div>
                            {/* Site Listing */}
                            <div className={'pb-[35px]'}>
                                <button className={'font-bold'}>Site Listing</button>
                                <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                    <thead>
                                        <tr className='bg-black'>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Sr no.</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Id</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Site CreatedAt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            siteList && siteList.length ? siteList.map((e,index) =>
                                                <tr onClick={() => handleDetail(e)} className={`cursor-pointer ${siteList.length === index + 1 ? '' : 'border-b border-b-black'} ${index%2 ? 'bg-slate-100' : ''}`} key={index}>
                                                    <td className="w-[100px] px-2 lg:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{index + 1}</td>
                                                    <td className="w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e._id}</td>
                                                    <td className="w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e.name}</td>
                                                    <td className="w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{moment(e.createdAt).format('DD/MM/YYYY')}</td>
                                                </tr>
                                            ):
                                            <tr>
                                                <td colSpan="4" className="text-center py-4">No Record Found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* All Listing */}
                            <div className={'pb-[35px]'}>
                                <button className={'font-bold'}>All Listing</button>
                                <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                    <thead>
                                        <tr className='bg-black'>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                            {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allActivityLogsDetail && allActivityLogsDetail.length && allActivityLogsDetail
                                            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                            .map((e, index) => (
                                                <tr onClick={() => handleUserDeatil(e)}
                                                    className={`cursor-pointer ${
                                                        index % 2 ? 'bg-slate-100' : ''
                                                    } border-b border-b-black`}
                                                    key={index}
                                                >
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].name}
                                                    </td>
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].email}
                                                    </td>
                                                    {userPost == "SuperAdmin" && (
                                                        <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                            {e.userId}
                                                        </td> 
                                                    )}
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.siteAreas[0]?.name}
                                                    </td>
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                </tr>
                                            ))}
                                            {allActivityLogsDetail.length === 0 && (
                                            <tr>
                                                <td colSpan='6' className='text-center py-4'>
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Visitors Listing */}
                            <div className={'pb-[35px]'}>
                                <button className={'font-bold'}>Visitors Listing</button>
                                <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                    <thead>
                                        <tr className='bg-black'>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                            {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                            <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visitorscanDetail && visitorscanDetail.length && visitorscanDetail
                                            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                            .map((e, index) => (
                                                <tr onClick={() => handleUserDeatil(e)}
                                                    className={`cursor-pointer ${
                                                        index % 2 ? 'bg-slate-100' : ''
                                                    } border-b border-b-black`}
                                                    key={index}
                                                >
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].name}
                                                    </td>
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].email}
                                                    </td>
                                                    {userPost == "SuperAdmin" && (<td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userId}
                                                    </td>)}
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.siteAreas[0]?.name}
                                                    </td>
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                </tr>
                                            ))}
                                            {visitorscanDetail.length === 0 && (
                                            <tr>
                                                <td colSpan='6' className='text-center py-4'>
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Workers Listing */}
                            <div className={'pb-[35px]'}>
                                <button className={'font-bold'}>Workers Listing</button>
                                <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                    <thead>
                                                <tr className='bg-black'>
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                                    {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                                </tr>
                                    </thead>
                                    <tbody>
                                        {workerDetail && workerDetail.length && workerDetail
                                            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                            .map((e, index) => (
                                                <tr
                                                    onClick={() => handleUserDeatil(e)}
                                                    className={`cursor-pointer ${
                                                        index % 2 ? 'bg-slate-100' : ''
                                                    } border-b border-b-black`}
                                                    key={index}
                                                >
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].name}
                                                    </td>
                                                    <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userData[0].email}
                                                    </td>
                                                    {userPost == "SuperAdmin" && (<td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.userId}
                                                    </td>)}
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.siteAreas[0]?.name}
                                                    </td>
                                                    <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                        {moment(e.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                </tr>
                                            ))}
                                        {workerDetail.length === 0 && (
                                            <tr>
                                                <td colSpan='6' className='text-center py-4'>
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> 
                            </div>

                        </div>
                    )}

                    {activeTab === 'siteListing' && (
                        <div>
                            <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                <thead>
                                    <tr className='bg-black'>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Sr no.</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Id</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Site CreatedAt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        siteList && siteList.length ? siteList.map((e,index) =>
                                            <tr onClick={() => handleDetail(e)} className={`cursor-pointer ${siteList.length === index + 1 ? '' : 'border-b border-b-black'} ${index%2 ? 'bg-slate-100' : ''}`} key={index}>
                                                <td className="w-[100px] px-2 lg:min-w-[auto] min-w-[100px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{index + 1}</td>
                                                <td className="w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e._id}</td>
                                                <td className="w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e.name}</td>
                                                <td className="w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{moment(e.createdAt).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        ):
                                        <tr>
                                            <td colSpan="4" className="text-center py-4">No Record Found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                        </div>
                    )}

                    {activeTab === 'allActivityLogs' && (

                    <div>
                        <table className={`table-auto w-full lg:table block overflow-scroll`}>
                            <thead>
                                <tr className='bg-black'>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                    {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                    <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allActivityLogsDetail
                                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                    .map((e, index) => (
                                        <tr onClick={() => handleUserDeatil(e)}
                                            className={`cursor-pointer ${
                                                index % 2 ? 'bg-slate-100' : ''
                                            } border-b border-b-black`}
                                            key={index}
                                        >
                                            <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.userData[0].name}
                                            </td>
                                            <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.userData[0].email}
                                            </td>
                                            {userPost == "SuperAdmin" && (
                                                <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userId}
                                                </td> 
                                            )}
                                            <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.siteAreas[0]?.name}
                                            </td>
                                            <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                            </td>
                                            <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                {moment(e.createdAt).format('DD/MM/YYYY')}
                                            </td>
                                            <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                {moment(e.createdAt).format('HH:mm:ss')}
                                            </td>
                                        </tr>
                                    ))}
                                    {allActivityLogsDetail.length === 0 && (
                                    <tr>
                                        <td colSpan='6' className='text-center py-4'>
                                            No Record Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>   
                        <div className='flex justify-between items-center mt-4'>
                            <div className={'space-x-2'}>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        <div className='text-xl'>Page {currentPage} of {totalPages}</div>
                    </div>
                    </div>
                    )}

                    {activeTab === 'visitersLogs' && (
                        <div>
                            <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                <thead>
                                    <tr className='bg-black'>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                        {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                        <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {visitorscanDetail
                                        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                        .map((e, index) => (
                                            <tr onClick={() => handleUserDeatil(e)}
                                                className={`cursor-pointer ${
                                                    index % 2 ? 'bg-slate-100' : ''
                                                } border-b border-b-black`}
                                                key={index}
                                            >
                                                <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userData[0].name}
                                                </td>
                                                <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userData[0].email}
                                                </td>
                                                {userPost == "SuperAdmin" && (<td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userId}
                                                </td>)}
                                                <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.siteAreas[0]?.name}
                                                </td>
                                                <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                                </td>
                                                <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                    {moment(e.createdAt).format('DD/MM/YYYY')}
                                                </td>
                                                <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {moment(e.createdAt).format('HH:mm:ss')}
                                                </td>
                                            </tr>
                                        ))}
                                        {visitorscanDetail.length === 0 && (
                                        <tr>
                                            <td colSpan='6' className='text-center py-4'>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>   
                            <div className='flex justify-between items-center mt-4'>
                                <div className={'space-x-2'}>
                                    <button
                                        className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                            currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                        }`}
                                        onClick={() => goToPage(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                            currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                        }`}
                                        onClick={() => goToPage(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                                <div className='text-xl'>Page {currentPage} of {totalPages}</div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'workerLogs' && (

                        <div>
                            <table className={`table-auto w-full lg:table block overflow-scroll`}>
                                <thead>
                                            <tr className='bg-black'>
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[100px] lg:min-w-[auto] min-w-[100px] leading-[normal]">Name</th>
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">Email</th>
                                                {userPost == "SuperAdmin" && (<th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[190px] lg:min-w-[auto] min-w-[350px] leading-[normal]">User id</th>)}
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[290px] lg:min-w-[auto] min-w-[300px] leading-[normal]">Site Name</th>
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Status</th>
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">At</th>
                                                <th className="px-2 py-2 text-left font-family-Quicksand-Bold text-[18px] text-white w-[155px] lg:min-w-[auto] min-w-[250px] leading-[normal]">Time</th>
                                            </tr>
                                </thead>
                                <tbody>
                                    {workerDetail
                                        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                                        .map((e, index) => (
                                            <tr
                                                onClick={() => handleUserDeatil(e)}
                                                className={`cursor-pointer ${
                                                    index % 2 ? 'bg-slate-100' : ''
                                                } border-b border-b-black`}
                                                key={index}
                                            >
                                                <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userData[0].name}
                                                </td>
                                                <td className='w-[190px] px-2 lg:min-w-[auto] min-w-[350px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userData[0].email}
                                                </td>
                                                {userPost == "SuperAdmin" && (<td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.userId}
                                                </td>)}
                                                <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.siteAreas[0]?.name}
                                                </td>
                                                <td className='w-[290px] px-2 lg:min-w-[auto] min-w-[300px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {e.isRestricted === true ? 'Not Validate' : 'Validate'}
                                                </td>
                                                <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px                                                text-[18px] text-stroke'>
                                                    {moment(e.createdAt).format('DD/MM/YYYY')}
                                                </td>
                                                <td className='w-[155px] px-2 lg:min-w-[auto] min-w-[250px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke'>
                                                    {moment(e.createdAt).format('HH:mm:ss')}
                                                </td>
                                            </tr>
                                        ))}
                                    {workerDetail.length === 0 && (
                                        <tr>
                                            <td colSpan='6' className='text-center py-4'>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>   
                            <div className='flex justify-between items-center mt-4'>
                            <div className={'space-x-2'}>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className={`py-2 px-4 rounded-lg font-semibold text-white ${
                                        currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                    }`}
                                    onClick={() => goToPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                            <div className='text-xl'>
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>

                        </div>
                    )}
                    
                </div>
            }
        </div>
    );
};

export default SiteLisitng;