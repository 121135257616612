import pageNotFound from "../../../assets/images/svg/404img.ea7f7262.svg" 

const NotFound = () => {
    return(
        <div className="flex items-center justify-center flex-col text-center px-[15px] mt-[50px]">
            <img src={pageNotFound} alt="pageNotFound" />
            <div className="error-text">
                <h3><strong>Opps, page not found</strong></h3>
                <p>We are very sorry for the inconvenience. It looks like you’re trying to access a page that has been deleted or never even existed.</p>
            </div>
        </div>
    );
};

export default NotFound; 