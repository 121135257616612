import { App } from "realm-web";

export const DB_REF = {value: null};
export  function mongoConnection() {
    return new Promise((resolve, reject) => {
        try {
            const app = new App({ id: 'application-0-oszejan' });
            if(app.currentUser) {
                const mongodb = app.currentUser.mongoClient("mongodb-atlas");
                DB_REF.value = mongodb.db('Eeze');
                resolve(DB_REF.value);
            } else {
                reject("No user")
            }
        } catch (e) {
            console.error("Failed to connect to MongoDB:", e);
            reject(e);
        }
    })
}